export const DATA_LOCATION = 'data-location';
export const INSERT = 'Insert';
export const INSERT_CTA = 'Insert Call To Action';
export const INSERT_MENU_CONTENT = 'Insert Menu Content';
export const MODIFY_CTA = 'Modify Call To Action';
export const MODIFY_LINKS = 'Modify Links On Page';
export const PRIMARY_BANNER = 'primary-banner';
export const RESTRICTIVELY_MODIFY_CTA = 'Restrictively Modify Call To Action';
export const UPDATE = 'Update';

export const VEHICLE_BADGE = 'vehicle-badge';
export const VEHICLE_CTAS = 'vehicle-ctas';
export const VEHICLE_MEDIA = 'vehicle-media';
export const VEHICLE_PAYMENTS = 'vehicle-payments';
export const VEHICLE_PRICING = 'vehicle-pricing';

export const VEHICLE_BADGE_CUSTOM = `${VEHICLE_BADGE}-custom`;
export const VEHICLE_CTAS_CUSTOM = `${VEHICLE_CTAS}-custom`;
export const VEHICLE_MEDIA_CUSTOM = `${VEHICLE_MEDIA}-custom`;
export const VEHICLE_PAYMENTS_CUSTOM = `${VEHICLE_PAYMENTS}-custom`;
export const VEHICLE_PRICING_CUSTOM = `${VEHICLE_PRICING}-custom`;

// List of integrations allowed to use the primary placeholder insert location on the SRP.
export const PRIMARY_PLACEHOLDER_INTEGRATIONS = ['mywallet', 'toyota-smartpath-banners'];
